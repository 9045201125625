<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <brand :isWhite="true" />
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        :class="`d-none d-lg-flex flex-column align-items-center justify-content-between p-0 bg-primary`"
        style="height: 100vh"
      >
        <div
          class="bg-white w-100 text-center d-flex align-items-center justify-content-center"
          style="height: 20%"
        >
          <b-img
            v-if="!isOCB"
            :src="require('@/assets/images/logo/tingedu-logo-horizontal.png')"
            style="max-width: 50%; max-height: 60%"
          ></b-img>
          <div v-else class="d-flex flex-row">
            <b-img
              :src="require('@/assets/images/logo/logo-ocb.svg')"
              style="max-width: 80%; max-height: 80%"
            ></b-img>
            <div
              style="width: 2px; background-color: #e39717"
              class="rounded align-items-stretch mx-2"
            ></div>
            <div
              class="text-left d-flex flex-column justify-content-center align-items-center"
            >
              <!-- <div style="color: #002b70">Ngân hàng TMCP Phương Đông</div> -->
              <div
                style="color: #e39717; font-style: italic"
                class="font-medium-3"
              >
                Niềm tin và thịnh vượng
              </div>
              <!-- <div style="color: #002b70">Thương hiệu Quốc gia 2020 - 2022</div> -->
            </div>
          </div>
        </div>
        <div
          class="w-100 text-center d-flex flex-column align-items-center justify-content-center font-weight-bolder bg-primary"
          style="height: 80%; background-color: #af2031"
        >
          <div v-if="isOCB" style="font-size: 86px" class="text-white">
            OCB SCHOOL
          </div>
          <div
            v-if="isOCB"
            class="text-uppercase"
            style="color: #fee01c; font-size: 30px"
          >
            Giải pháp thu & quản lý học phí tự động
          </div>
          <!-- <div
            v-if="isOCB"
            class="bg-white text-primary mt-3"
            style="
              padding-top: 4px;
              padding-bottom: 4px;
              padding-left: 40px;
              padding-right: 40px;
              border-radius: 40px;
            "
          >
            Thông tin liên hệ
          </div>
          <div v-if="isOCB" class="text-white mt-1" style="font-size: 18px">
            Ban Dự án Open Banking
          </div>
          <div v-if="isOCB" class="mt-20">
            <span><feather-icon icon="MailIcon" class="text-white" /></span
            ><span class="text-white pl-50 font-weight-normal"
              >openbanking@ocb.com.vn</span
            >
          </div> -->

          <div
            v-if="!isOCB"
            class="text-uppercase text-white"
            style="font-size: 40px"
          >
            Phần mềm quản lý
          </div>
          <div
            v-if="!isOCB"
            class="text-uppercase pt-0"
            style="color: #f5c116; font-size: 40px"
          >
            Thu phí tự động
          </div>
          <div v-if="!isOCB" class="text-white pt-3" style="font-size: 16px">
            Thiết kế dành riêng cho lĩnh vực
          </div>
          <div
            v-if="!isOCB"
            class="text-uppercase font-weight-bolder m-1 p-1"
            style="
              background-color: #f5c116;
              color: #002b70;
              font-size: 30px;
              border-color: #002b70;
              border-radius: 15px;
              border-width: 2px;
            "
          >
            Giáo dục và đào tạo
          </div>
          <div
            v-if="!isOCB"
            class="text-uppercase pt-2"
            style="color: #f5c116; font-size: 16px"
          >
            Liên hệ ngay với chúng tôi
          </div>
          <div v-if="!isOCB" class="d-flex flex-row align-items-stretch mt-1">
            <div class="bg-white">
              <b-img
                :src="require('@/assets/images/pages/website_qr.svg')"
              ></b-img>
            </div>
            <div
              class="text-left pl-50 d-flex flex-column justify-content-between"
            >
              <div>
                <span
                  ><feather-icon
                    icon="PhoneIcon"
                    style="color: #f5c116" /></span
                ><span class="text-white pl-50">0247 1088 968</span>
              </div>
              <div>
                <span
                  ><feather-icon icon="MailIcon" style="color: #f5c116" /></span
                ><span class="text-white pl-50">edu@tingee.vn</span>
              </div>
              <div>
                <span
                  ><feather-icon
                    icon="GlobeIcon"
                    style="color: #f5c116" /></span
                ><span class="text-white pl-50">www.edu.tingee.vn</span>
              </div>
              <div>
                <span
                  ><feather-icon
                    icon="MapPinIcon"
                    style="color: #f5c116" /></span
                ><span class="text-white pl-50">Hà Nội & TP. Hồ Chí Minh</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="">
          <b-img :src="require('@/assets/images/illustration/leftimg.svg')">
          </b-img>
        </div>
        <div
          class="text-white flex-row align-items-center justify-content-center"
        >
          <h3 class="text-uppercase text-white font-weight-bold text-center">
            Phần mềm Quản lý thu phí tự động
          </h3>
          <h3
            class="text-uppercase text-white font-weight-bold text-center mt-1"
          >
            dành riêng cho lĩnh vực Giáo dục & Đào tạo
          </h3>
          <div class="d-flex align-items-center justify-content-center mt-4">
            <b-img
              style="width: 50%"
              :src="
                require('@/assets/images/logo/tingedu-logo-white-horizontal.png')
              "
            />
          </div>
        </div> -->
        <!-- <div class="">
          <b-img :src="require('@/assets/images/illustration/rightimg.svg')">
          </b-img>
        </div> -->

        <!-- <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-0"
        >
          <b-img
            fluid
            :src="brandConfig.loginBgImage"
            alt="Login"
            class=""
            style="max-height: 80vh"
          />
        </div> -->
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1"> Đăng ký TingEdu 🚀 </b-card-title>
          <b-card-text class="mb-2">
            Gạch nợ tức thì, vì sao không thử???
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm" #default="{ invalid }">
            <b-form class="auth-register-form mt-2" @submit.prevent="register">
              <!-- name -->
              <b-form-group label="Họ tên">
                <template v-slot:label>
                  Tên cơ sở giáo dục
                  <span class="text-danger"> (*) </span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="name"
                    name="name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Nguyễn Nam Phong"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- phone -->
              <b-form-group label="Số điện thoại">
                <template v-slot:label>
                  Số điện thoại
                  <span class="text-danger"> (*) </span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="phone"
                  vid="phone"
                  rules="required"
                >
                  <b-form-input
                    id="phone"
                    v-model="phone"
                    name="phone"
                    :state="errors.length > 0 ? false : null"
                    placeholder="0868987355"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group label="Địa chỉ email">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  vid="email"
                  rules="email"
                >
                  <b-form-input
                    id="email"
                    v-model="email"
                    name="email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="phongnn@tingee.vn"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group label="Mật khẩu">
                <template v-slot:label>
                  Mật khẩu
                  <span class="text-danger"> (*) </span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Mật khẩu"
                  vid="password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group label="Xác nhận mật khẩu">
                <template v-slot:label>
                  Xác nhận mật khẩu
                  <span class="text-danger"> (*) </span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Xác nhận mật khẩu"
                  vid="passwordConfirm"
                  rules="required|password|confirmed:password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="passwordConfirm"
                      v-model="passwordConfirm"
                      class="form-control-merge"
                      :type="passwordConfirmFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="passwordConfirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordConfirmToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordConfirmVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="agreement"
                  rules="agreement"
                >
                  <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="status"
                    name="checkbox"
                  >
                    Tôi đồng ý với
                    <b-link
                      href="https://healthcarenow.larksuite.com/docx/TAGDdWNEhoMWYwxSkmqu4EvXshc"
                      target="_blank"
                    >
                      Chính sách sử dụng
                    </b-link>
                    và
                    <b-link
                      href="https://healthcarenow.larksuite.com/docx/TAGDdWNEhoMWYwxSkmqu4EvXshc"
                      target="_blank"
                    >
                      Điều khoản bảo mật
                    </b-link>
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid || isLoading"
              >
                <b-spinner v-if="isLoading" small />
                {{ isLoading ? "Đang đăng ký" : "Đăng ký" }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Bạn đã có tài khoản?</span>
            <b-link :to="{ name: 'auth-login' }">
              <span>&nbsp;Đăng nhập</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BSpinner,
} from "bootstrap-vue";
import { required, agreement } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
// eslint-disable-next-line import/no-cycle
import useJwt from "@/auth/jwt/useJwt";
import md5 from "md5";
import { togglePasswordConfirmVisibility } from "@/layouts/mixins/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Brand from "@/layouts/components/Brand.vue";
import { $themeConfig, $brandConfig } from "@themeConfig";
export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,

    BSpinner,
    Brand,
  },
  mixins: [togglePasswordVisibility, togglePasswordConfirmVisibility],
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      password: "",
      passwordConfirm: "",
      status: true,
      // validation
      required,
      agreement,
      isLoading: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordConfirmToggleIcon() {
      return this.passwordConfirmFieldType === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app;

    const brandConfig = $brandConfig;
    const isOCB = process.env.VUE_APP_PROVIDER === "ocb";

    return {
      appName,
      appLogoImage,
      brandConfig,
      isOCB,
    };
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          let data = {
            name: this.name,
            phone: this.phone,
            password: md5(this.password),
            role: "owner",
          };
          if (this.email && this.email.length > 0) {
            data = {
              ...data,
              email: this.email,
            };
          }
          useJwt
            .register(data)
            .then((response) => {
              this.isLoading = false;
              const { user, accessToken, refreshToken } = response.data;
              useJwt.setToken(accessToken);
              useJwt.setRefreshToken(refreshToken);
              localStorage.setItem("userData", JSON.stringify(user));
              this.$ability.update([
                {
                  action: "manage",
                  subject: "all",
                },
              ]);
              this.$router.replace({ name: "invoice-list" }).then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Xin chào ${user.name}`,
                    icon: "UserIcon",
                    variant: "success",
                    text: `Đăng ký tài khoản thành công`,
                  },
                });
              });
              this.isLoading = false;
            })
            .catch((error) => {
              this.isLoading = false;

              if (
                error.response &&
                error.response.data &&
                error.response.data.errors
              ) {
                this.$refs.registerForm.setErrors(error.response.data.errors);
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Thông báo",
                    text: "Lỗi hệ thống. Vui lòng chờ trong giây lát hoặc liên hệ hỗ trợ kỹ thuật để được trợ giúp kịp thời. Hotline liên hệ: 0868987355",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              }
            });
        }
      });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
